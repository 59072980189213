<template>
  <v-expand-transition
    mode="out-in"
  >
    <div>
      <pop-up-window
        v-if="timeHasPassed && !isPinCodeSet || showPinPopUp"
        rounded="xl"
      >
        <change-pin-code-form
          @onPinPopUpClose="togglePinPopUp(false)"
        />
      </pop-up-window>

      <tab-header
        v-if="isAuthenticated"
        class="block-wrapper mx-auto mb-2"
        icon-name="account-outline"
        text="Мій профіль"
      >
        <v-btn
          rounded
          outlined
          color="error"
          class="caption text-sm-body-2 font-weight-medium"
          @click="togglePinPopUp(true)"
        >
          змінити пароль
        </v-btn>
      </tab-header>

      <v-row
        v-if="isAuthenticated"
        no-gutters
        class="flex-column mx-auto pa-0 block-wrapper"
      >
        <div class="border-red border-radius-10 px-4 pb-3 px-sm-10 px-md-12 pt-sm-3 pb-sm-7
                    px-lg-16">
          <v-subheader class="error--text text-h6 text-lg-h5 font-weight-medium pa-0">
            Персональні дані*
          </v-subheader>
          <p class="font-weight-medium my-2">
            ПІБ:
            <span
              class="font-weight-regular"
              v-text="clientData.last_name +' '+  clientData.first_name
               +' '+ clientData.middle_name || defaultData"
            ></span>
          </p>
          <p class="font-weight-medium mb-2">
            Номер телефону:
            <span
              class="font-weight-regular"
              v-text="clientData.phone || defaultData"
            ></span>
          </p>
          <p class="font-weight-medium mb-0">
            E-mail:
            <span
              class="font-weight-regular"
              v-text="clientData.email || defaultData"
            ></span>
          </p>
        </div>
        <p class="caption text-md-body-2 mt-1 text-end opacity-8 text--text">
          *Для оновлення даних зверніться, будь ласка, до Вашого персонального асистента.
        </p>
      </v-row>
    </div>
  </v-expand-transition>
</template>

<script>
import PopUpWindow from '@/components/common/PopUpWindow';
import ChangePinCodeForm from '@/views/user-profile/ChangePinCodeForm';
import { mapActions, mapGetters, mapState } from 'vuex';
import TabHeader from '@/components/common/TabHeader';
import pageLoaderMixin from '@/mixins/pageLoaderMixin';
// eslint-disable-next-line import/no-cycle
import productRedirect from '../../utils/productRedirect';

export default {
  name: 'Index',
  title: 'Мій профіль',
  components: {
    TabHeader,
    PopUpWindow,
    ChangePinCodeForm,
  },
  mixins: [pageLoaderMixin],
  data() {
    return {
      defaultData: 'Дані не вказано',
      timeHasPassed: false,
      showPinPopUp: false,
    };
  },
  computed: {
    ...mapGetters('auth', [
      'isAuthenticated',
      'pinCodeSet',
    ]),
    ...mapState('auth', [
      'isPinCodeSet',
    ]),
    clientData() {
      return this.$store.state.clientInfo.clientData;
    },
  },
  beforeMount() {
    this.makeDelay();
    const userData = 'fullName phone email';
    this.getClientMainInfo(userData)
      .then(() => this.hidePagePreloader())
      .catch((err) => console.error(err));
  },
  mounted() {
    this.hidePagePreloader();
    const timer = setTimeout(() => {
      if (Object.keys(this.$route.query).includes('inv') && this.isPinCodeSet) {
        if (Object.keys(this.$route.query).includes('inv')) {
          this.$router.push({ path: `/redirect?inv=${productRedirect.getJWTFromProductParam()}` });
        }
      }
      clearTimeout(timer);
    }, 1000);
  },
  methods: {
    ...mapActions('clientInfo', [
      'getClientMainInfo',
    ]),
    makeDelay() {
      const delay = this.pinCodeSet ? 0 : 800;
      // eslint-disable-next-line no-return-assign
      const timeout = setTimeout(() => {
        this.timeHasPassed = true;
        clearTimeout(timeout);
      }, delay);
    },
    togglePinPopUp(bool) {
      this.showPinPopUp = bool;
    },
  },
};
</script>

<style lang="scss" scoped>
.block-wrapper {
  @media screen and (max-width: 1264px) {
    @media screen and (min-width: 400px) {
      max-width: 390px;
    }

    @media screen and (min-width: 650px) {
      width: 600px;
    }
  }
}
</style>
