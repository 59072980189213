<template>
  <div>
    <v-slide-x-reverse-transition
      group
      mode="in-out"
    >
      <v-alert
        v-for="(error) in serverError.nonFieldErrors"
        :key="error.code"
        :value="authError"
        outlined
        text
        type="error"
      >
        {{ error.message }}
      </v-alert>
    </v-slide-x-reverse-transition>
    <v-slide-x-reverse-transition
      group
      mode="in-out"
    >
      <v-alert
        v-for="(error) in serverError.key"
        :key="error.code"
        :value="authError"
        outlined
        text
        type="error"
      >
        {{ error.message }}
      </v-alert>
    </v-slide-x-reverse-transition>
    <v-slide-x-reverse-transition
      group
      mode="in-out"
    >
      <v-alert
        v-for="(error) in globalErrors"
        :key="error.code"
        :value="authError"
        outlined
        text
        type="error"
      >
        {{ error.message }}
      </v-alert>
    </v-slide-x-reverse-transition>

    <notification
      v-if="errorNotification"
      type="error"
    >
      Щось пішло не так. Спробуйте ще раз
    </notification>

    <notification
      v-if="successNotification"
      type="success"
    >
      Пін-код успішно змінено
    </notification>

    <v-card
      elevation="6"
      class="wrapper border-radius-10 pb-4"
      color="background"
    >
      <v-card-title class="d-flex justify-space-between align-center text--text">
        <template v-if="pinCodeSet">
          <span>Редагування паролю</span>

          <v-icon
            class="close-btn"
            @click="onPinPopUpClose"
          >
            mdi-close
          </v-icon>
        </template>

        <template v-else>
          <span style="word-break: keep-all;text-align: center">
            Придумайте пароль для розблокування сесії при наступній авторизації
          </span>
        </template>
      </v-card-title>
      <v-card-text class="pb-0">
        <validation-observer
          ref="observer"
        >
          <form
            class="password-form"
            @submit.prevent="submit"
          >
            <template
              v-if="pinCodeSet"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Старий пароль"
                vid="mainPin"
                :rules="{
                  required: true,
                  alphaNum: true,
                  min: 4,
                  max: 20
                }"
              >
                <v-text-field
                  v-model="pin1"
                  :error-messages="errors"
                  label="Старий пароль"
                  rounded
                  filled
                  outlined
                  dense
                  color="text"
                  :success="errors.length === 0 && pin1.length >= 4 && pin1.length <= 20"
                  :type="show1 ? 'text' : 'password'"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show1 = !show1"
                />
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="Новий пароль"
                vid="newPin"
                :rules="{
                  required: true,
                  alphaNum: true,
                  min: 4,
                  max: 20,
                  pinCodesMatch: '@mainPin'
                }"
              >
                <v-text-field
                  v-model="pin2"
                  :error-messages="errors"
                  label="Новий пароль"
                  rounded
                  filled
                  outlined
                  dense
                  color="text"
                  :success="errors.length === 0 && pin2.length >= 4 && pin2.length <= 20"
                  :type="show2 ? 'text' : 'password'"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show2 = !show2"
                />
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="Підтвердити новий пароль"
                :rules="{
                  required: true,
                  alphaNum: true,
                  min: 4,
                  max: 20,
                  pinConfirmed: '@newPin'
                }"
              >
                <v-text-field
                  v-model="pin3"
                  :error-messages="errors"
                  label="Підтвердити новий пароль"
                  rounded
                  filled
                  outlined
                  dense
                  color="text"
                  :success="errors.length === 0 && pin3.length >= 4 && pin3.length <= 20"
                  :type="show3 ? 'text' : 'password'"
                  :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show3 = !show3"
                />
              </validation-provider>
            </template>

            <template v-else>
              <validation-provider
                v-slot="{ errors }"
                name="Пароль"
                vid="mainPin"
                :rules="{
                  required: true,
                  alphaNum: true,
                  min: 4,
                  max: 20
                }"
              >
                <v-text-field
                  v-model="pin1"
                  :error-messages="errors"
                  label="Пароль"
                  rounded
                  filled
                  outlined
                  dense
                  color="text"
                  :success="errors.length === 0 && pin1.length >= 4 && pin1.length <= 20"
                  :type="show1 ? 'text' : 'password'"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show1 = !show1"
                />
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="Повторний пароль"
                :rules="{
                  required: true,
                  alphaNum: true,
                  min: 4,
                  max: 20,
                  pinConfirmed: '@mainPin'
                }"
              >
                <v-text-field
                  v-model="pin2"
                  :error-messages="errors"
                  label="Повторний пароль"
                  rounded
                  filled
                  outlined
                  dense
                  color="text"
                  :success="errors.length === 0 && pin2.length >= 4 && pin2.length <= 20"
                  :type="show2 ? 'text' : 'password'"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show2 = !show2"
                />
              </validation-provider>
            </template>

            <div
              class="position-relative"
              style="display: flex"
            >
              <block-preloader
                v-if="blockPreloaderIsVisible"
              />

              <buttons-block
                :class="{ 'visibility-hidden': blockPreloaderIsVisible }"
                @continueButtonClick="submit"
                @clearButtonClick="clear"
              />
            </div>
          </form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ButtonsBlock from '@/components/common/ButtonsBlock';
import { mapActions, mapGetters, mapState } from 'vuex';
import blockPreloaderMixin from '@/mixins/blockPreloaderMixin';
import errorNotificationMixin from '@/mixins/errorNotificationMixin';
import successNotificationMixin from '@/mixins/successNotificationMixin';
import Notification from '@/components/common/Notification';
import BlockPreloader from '@/components/common/BlockPreloader';
import RouteNamesService from '@/services/route-names-service';
import productRedirect from '../../utils/productRedirect';
import getProductByCode from '../../mixins/getProductByCode';

export default {
  name: 'PinCodeChanging',
  components: { BlockPreloader, Notification, ButtonsBlock },
  mixins: [blockPreloaderMixin, errorNotificationMixin, successNotificationMixin, getProductByCode],
  data() {
    return {
      show1: false,
      show2: false,
      show3: false,
      pin1: '',
      pin2: '',
      pin3: '',
    };
  },
  computed: {
    ...mapState('auth', [
      'timerTime',
      'authError',
      'globalErrors',
      'serverError',
      'isPinCodeSet',
    ]),
    ...mapGetters('auth', [
      'pinCodeSet',
    ]),
  },
  mounted() {
    this.disableTabKeyForEyeIcons();
  },
  methods: {
    ...mapActions('auth', [
      'updateClientsPinStore',
      'getClientMainInfo',
    ]),
    ...mapActions('cashForm', [
      'getActiveOrderStore',
    ]),
    disableTabKeyForEyeIcons() {
      document.querySelectorAll('.password-form .v-icon.mdi').forEach((item) => {
        item.setAttribute('tabindex', '-1');
      });
    },
    onPinPopUpClose() {
      this.$emit('onPinPopUpClose');
    },
    clearPinCodes() {
      // eslint-disable-next-line no-multi-assign
      this.pin1 = this.pin2 = this.pin3 = '';
      this.$refs.observer.reset();
    },
    handleProductRedirect() {
      if (Object.keys(this.$route.query).includes('inv')) {
        this.$router.push({ path: `/redirect?inv=${productRedirect.getJWTFromProductParam()}` });
      } else {
        this.$router.push({ path: `/redirect?product=${productRedirect.getJWTFromProductParam()}` });
      }
    },
    handleCommonRedirect() {
      let path = RouteNamesService.cashForm;
      const getClientMainInfoResult = this.getClientMainInfo();
      if (getClientMainInfoResult) {
        path = RouteNamesService.userCredits;
      }
      if (this.$router.history.current.name !== path) {
        this.$router.push({ name: path });
      }
    },
    async submit() {
      try {
        const isValid = await this.$refs.observer.validate();
        if (isValid) {
          if (!this.isPinCodeSet) {
            await this.$store.dispatch('auth/setClientsPinStore', { pin: this.pin1 }).then((data) => {
              if (data.success) {
                const isProductRedirect = productRedirect.checkIfURLIncludesProductParam();
                if (isProductRedirect) {
                  this.handleProductRedirect();
                } else {
                  this.handleCommonRedirect();
                }
              }
            });
          } else {
            this.toggleBlockPreloader(true);
            const updateClientsPinResult = await this.updateClientsPinStore({
              oldPin: this.pin1,
              newPin: this.pin2,
            });
            this.toggleBlockPreloader(false);
            if (updateClientsPinResult) {
              this.clearPinCodes();
              this.toggleSuccessMessage();
              const timeout = setTimeout(() => {
                this.onPinPopUpClose();
                clearTimeout(timeout);
              }, 3000);
            } else {
              this.toggleErrorMessage();
            }
          }
        }
      } catch (error) {
        this.toggleBlockPreloader(false);
        this.toggleErrorMessage();
        console.error(error);
      }
    },
    clear() {
      this.pin1 = '';
      this.pin2 = '';
      this.$refs.observer.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
  .wrapper {
    @media screen and (min-width: 600px) {
      min-width: 500px;
    }
  }

  .close-btn {
    cursor: pointer;
  }
</style>
